<template>
  <div>
    <md-table
      md-card
      :md-sort.sync="currentSort"
      :md-sort-fn="customSort"
      :md-sort-order.sync="currentOrder"
      table-header-color="blue"
      :value="users"
    >
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Список пользователей</h1>
        </div>

        <div class="md-toolbar-section-end">
          <md-field
            class="filter-param__text"
            :md-clearable="true"
            @md-clear="nickname = ''"
          >
            <label>Никнейм</label>
            <md-input v-model.trim="nickname" :disabled="isLoaderVisible" />
          </md-field>

          <md-field
            class="filter-param__date"
            :md-clearable="true"
            @md-clear="dateFrom = ''"
          >
            <md-datepicker
              v-model="dateFrom"
              :disabled="isLoaderVisible"
              :md-model-type="String"
            >
              <label>От</label>
            </md-datepicker>
          </md-field>

          <md-field
            class="filter-param__date"
            :md-clearable="true"
            @md-clear="dateTo = ''"
          >
            <md-datepicker
              v-model="dateTo"
              :disabled="isLoaderVisible"
              :md-model-type="String"
            >
              <label>До</label>
            </md-datepicker>
          </md-field>

          <md-button
            class="md-raised md-primary"
            :disabled="isLoaderVisible"
            @click="changePage(1)"
          >
            Найти
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        class="cursor-pointer"
        @click="$router.push(`/users/${item.id}`)"
      >
        <md-table-cell md-label="ID" md-sort-by="id">
          {{ item.id }}
        </md-table-cell>

        <md-table-cell class="no-wrap" md-label="Никнейм" md-sort-by="username">
          {{ item.userName }}
        </md-table-cell>

        <md-table-cell class="no-wrap" md-label="E-mail">
          {{ item.email }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во игр" md-sort-by="numberOfMatches">
          {{ item.numberOfMatches }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во побед">
          {{ item.numberOfWins }}
        </md-table-cell>

        <md-table-cell md-label="Среднее время сессии">
          {{ item.averageSessionTime }}
        </md-table-cell>

        <md-table-cell md-label="Дата регистрации">
          {{ formatDate(item.createDate) }}
        </md-table-cell>

        <md-table-cell md-label="E-mail подтвержден">
          {{ !!item.emailConfirmed ? "Да" : "Нет" }}
        </md-table-cell>

        <md-table-cell md-label="Премиум доступ">
          {{ !!item.isPremium ? "Да" : "Нет" }}
        </md-table-cell>

        <md-table-cell md-label="Дата окончания премиум доступа">
          {{ !!item.isPremium ? formatDate(item.premiumEndDate) : "" }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-warning"
            @click.stop="openChangePremiumModal(item.id)"
          >
            <md-icon>workspace_premium</md-icon>
            <md-tooltip md-direction="left">Изменить премиум доступ</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <Pagination
      :current-page="currentPage"
      :total-pages="totalPages"
      @change-page="changePage"
    />

    <EditPremiumModal
      v-if="showEditPremiumModal"
      :user-id="selectedUserId"
      @close="closeEditPremiumModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { formatDate } from "@/helpers/formatDate";

export default {
  components: {
    EditPremiumModal: () => import("@/components/Modals/EditPremiumModal"),
    Pagination: () => import("@/components/Ui/Pagination"),
  },

  data() {
    return {
      currentOrder: "asc",
      currentSort: "id",
      selectedUserId: null,
      showEditPremiumModal: false,
    };
  },

  computed: {
    ...mapGetters("users", ["dateBegin", "dateEnd", "limit", "userName"]),
    ...mapState(["isLoaderVisible"]),
    ...mapState("users", ["currentPage", "totalPages", "users"]),

    dateFrom: {
      get: function () {
        return this.dateBegin;
      },
      set: function (date) {
        this.setDateBegin(date);
      },
    },

    dateTo: {
      get: function () {
        return this.dateEnd;
      },
      set: function (date) {
        this.setDateEnd(date);
      },
    },

    nickname: {
      get: function () {
        return this.userName;
      },
      set: function (value) {
        this.setUserName(value);
      },
    },
  },

  methods: {
    ...mapActions("users", ["getUsers"]),
    ...mapMutations({
      setCurrentPage: "users/SET_CURRENT_PAGE",
      setDateBegin: "users/SET_DATE_BEGIN",
      setDateEnd: "users/SET_DATE_END",
      setOrderBy: "users/SET_ORDER_BY",
      setOrderDirection: "users/SET_ORDER_DIRECTION",
      setSkipUsers: "users/SET_SKIP",
      setUserName: "users/SET_USER_NAME",
      showLoader: "SET_SHOW_LOADER",
    }),

    async changePage(page) {
      this.setCurrentPage(page);
      this.setSkipUsers(this.limit * (page - 1));
      this.showLoader(true);
      await this.getUsers();
      this.showLoader(false);
    },

    async closeEditPremiumModal(needUpdate) {
      this.showEditPremiumModal = false;
      this.selectedUserId = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getUsers();
        this.showLoader(false);
      }
    },

    async customSort() {
      this.setOrderBy(this.currentSort);
      this.setOrderDirection(this.currentOrder);
      this.showLoader(true);
      await this.getUsers();
      this.showLoader(false);
    },

    formatDate(dateString) {
      return formatDate(new Date(dateString));
    },

    openChangePremiumModal(userId) {
      this.selectedUserId = userId;
      this.showEditPremiumModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-toolbar-section-end > * {
  margin-right: 30px;
}

.filter-param {
  &__date {
    width: 20%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.md-just-icon {
  z-index: 1;
}

::v-deep {
  .md-table-sortable-icon {
    left: unset;
    right: 0;
  }
}
</style>
