/**
 * Форматирует дату в dd.MM.yyyy
 *
 * @param   {Date}  date  - объект класса Date
 * @param   {Boolean}  isUTC  - флаг перевода времени
 *
 * @return  {String}        дата в формате dd.MM.yyyy
 */
export const formatDate = function (date, isUTC = false) {
  let dd = isUTC ? date.getUTCDate() : date.getDate();
  if (dd < 10) dd = '0' + dd;

  let mm = isUTC ? date.getUTCMonth() + 1 : date.getMonth() + 1; // месяц 1-12
  if (mm < 10) mm = '0' + mm;

  let yyyy = isUTC ? date.getUTCFullYear() : date.getFullYear();
  while (yyyy.length < 4) yyyy.unshift('0');

  return dd + '.' + mm + '.' + yyyy;
}

/**
 * Возвращает время в формате hh:mm
 *
 * @param   {Date}  date  - объект класса Date
 * @param   {Boolean}  isUTC  - флаг перевода времени
 *
 * @return  {String}        время в формате hh:mm
 */
export const getTime = function (date, isUTC = false) {
  let hh = isUTC ? date.getUTCHours() : date.getHours();
  if (hh < 10) hh = '0' + hh;

  let mm = isUTC ? date.getUTCMinutes() : date.getMinutes();
  if (mm < 10) mm = '0' + mm;

  return hh + ':' + mm;
}

/**
 * Форматирует дату в dd.MM.yyyy hh:mm
 *
 * @param   {Date}  date  - объект класса Date
 * @param   {Boolean}  isUTC  - флаг перевода времени
 *
 * @return  {String}        дата в формате dd.MM.yyyy hh:mm
 */
export const formatDateAndTime = function (date, isUTC = false) {
  return formatDate(date, isUTC) + ' ' + getTime(date, isUTC);
}